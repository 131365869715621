@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #ffffff;
  overflow: hidden;
  z-index: 1;
}

.background span {
  width: 37vmin;
  height: 37vmin;
  border-radius: 37vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 45ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


.background span:nth-child(0) {
  color: #ff809f;
  top: 77%;
  left: 6%;
  animation-duration: 25s;
  animation-delay: -23s;
  transform-origin: 2vw 1vh;
  box-shadow: -74vmin 0 9.965790354813977vmin currentColor;
}
.background span:nth-child(1) {
  color: #ff809f;
  top: 91%;
  left: 2%;
  animation-duration: 32s;
  animation-delay: -16s;
  transform-origin: 22vw -13vh;
  box-shadow: -74vmin 0 10.241253993321088vmin currentColor;
}
.background span:nth-child(2) {
  color: #ff809f;
  top: 91%;
  left: 19%;
  animation-duration: 13s;
  animation-delay: -45s;
  transform-origin: 19vw 1vh;
  box-shadow: 74vmin 0 10.018415403464054vmin currentColor;
}
.background span:nth-child(3) {
  color: #ff6694;
  top: 85%;
  left: 47%;
  animation-duration: 8s;
  animation-delay: -18s;
  transform-origin: 16vw 18vh;
  box-shadow: 74vmin 0 9.435214784843415vmin currentColor;
}
.background span:nth-child(4) {
  color: #ff809f;
  top: 9%;
  left: 98%;
  animation-duration: 50s;
  animation-delay: -22s;
  transform-origin: 23vw 23vh;
  box-shadow: 74vmin 0 9.853134942549142vmin currentColor;
}
