@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-display: block;
  font-style: normal;
  src: url('../../public/fonts/Inter-roman.var.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-display: block;
  font-style: italic;
  src: url('../../public/fonts/Inter-italic.var.woff2') format('woff2');
}

* {
  transition-property: color, background-color, border-color, fill;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  min-height: 100%;
}

:root {
  --main-color: #4a76a8;
}

.bg-main-color {
  background-color: var(--main-color);
}

.text-main-color {
  color: var(--main-color);
}

.border-main-color {
  border-color: var(--main-color);
}
.backface-hidden {
    backface-visibility: hidden;
}
.rotate-y-180 {
    transform: rotateY(180deg);
}

