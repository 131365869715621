.custom-scrollbar::-webkit-scrollbar {
    width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f0f0f0;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 20px;
    border: 3px solid #f0f0f0;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

input:disabled {
    opacity: 0.75;
}

.grayed-out {
    color: #A9A9A9;
    opacity: 0.75;
}

.card-grid-item {
    border-radius: 12px;
    overflow: hidden;
}

.card-image {
    width: 100%;
    height: auto;
}

.modal-box {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
}

.t-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
    " card1 card2 card3"
    ". card4."
    ". card5 .";
    gap: 1em;
}

.t-grid > :nth-child(1) {
    grid-area: card1;
}

.t-grid > :nth-child(2) {
    grid-area: card2;
}

.t-grid > :nth-child(3) {
    grid-area: card3;
}

.t-grid > :nth-child(4) {
    grid-area: card4;
}

.t-grid > :nth-child(5) {
    grid-area: card5;
}

#prctbl_1NiVuzIAnB3VH3p6gIrkwz6K {
    width: 100%;
    min-width: 20rem !important;
    min-height: 20rem;
    height: 100%;
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    table-layout: fixed;
}

.blockquote::before {
  content: open-quote;
}

.blockquote::after {
  content: close-quote;
}

.editorContainer {
  min-height: 50vh; /* This sets the minimum height to 50% of the viewport height */
  max-height: 80vh; /* This sets the maximum height to 100% of the viewport height */
  overflow-y: auto; /* This will add a scrollbar if the content exceeds the max-height */
}